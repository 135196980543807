import Link from 'next/link';
import CountUp from 'react-countup';
import getMenus from 'lib/get-menus';
import { GetStaticPropsResult } from 'next';
import { DrupalMenuLinkContent } from 'next-drupal';
import { ReactElement } from 'react';
import LayoutCommerce from 'components/layouts/LayoutCommerce';
import { FormattedMessage } from 'react-intl';

interface Custom404Props {
  menus: {
    main: DrupalMenuLinkContent[];
    footer: DrupalMenuLinkContent[];
    stickyShop: DrupalMenuLinkContent[];
  };
}

export default function Custom404() {
  const count = 404;

  return (
    <div className="my-20 flex flex-col items-center justify-center">
      <CountUp
        className="text-counter-m font-medium text-secondary lg:text-counter-d"
        end={count}
      />
      <p className="text-base-m font-light lg:text-base-d">
        <FormattedMessage defaultMessage={`Seite nicht gefunden`} id="Xnd9v7" />
      </p>
      <a href="/">
        <FormattedMessage
          defaultMessage={`Zurück zur Startseite`}
          id="m+qkrg"
        />
      </a>
    </div>
  );
}

Custom404.getLayout = (page: ReactElement) => {
  return <LayoutCommerce {...page.props}>{page}</LayoutCommerce>;
};

export async function getStaticProps(
  context
): Promise<GetStaticPropsResult<Custom404Props>> {
  // Load additional ressources if needed.
  const menus = await getMenus(context);

  return {
    props: {
      menus,
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 10 seconds
    revalidate: 10, // In seconds
  };
}
