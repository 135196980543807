import PreviewAlert from 'components/PreviewAlert';
import Header from 'components/blocks/Header';
import Footer from 'components/blocks/Footer';
import StickyShop from 'components/blocks/SecondaryMenu';
import { DrupalMenuLinkContent, DrupalNode } from 'next-drupal';
import Utilisation from 'components/blocks/Utilisation';
import Metatags from 'components/Metatags';
import FooterPartnerLogos from 'components/blocks/FooterPartnerLogos';
import { CartProvider } from 'lib/cart-context';

export interface LayoutCommerceProps {
  entity: DrupalNode;
  menus: {
    main: DrupalMenuLinkContent[];
    footer: DrupalMenuLinkContent[];
    stickyShop: DrupalMenuLinkContent[];
  };
  children?: React.ReactNode;
}

export default function LayoutCommerce({ entity, menus, children }) {
  const theme = entity?.field_theme ?? '';
  return (
    <>
      <Metatags entity={entity} />
      <div className={`${theme} text-secondary`}>
        <PreviewAlert />
        <Header menus={menus} />
        <main>{children}</main>
        <Footer menu={menus.footer} />
        <FooterPartnerLogos />
      </div>
    </>
  );
}
